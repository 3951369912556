import React from "react"
import SEO from "../components/seo"
import Layout from '../layouts/headerless'
import Simulador from '../components/pages/SimuladorPensionados'


const SimuladorPage = ({ pageContext }) => {
  console.log(pageContext)

  return (
    <Layout companyName={pageContext.company} >
      <SEO title={pageContext.title} />
      <Simulador pageContext={pageContext} />
    </Layout>
  )
}

export default SimuladorPage
